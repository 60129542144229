<template>
  <div>
    <h2 class="">WELCOME TO JMBComputers Management System</h2>
    <p>Maintained and Developed by: Jan Michael Besinga</p>

    <v-row class="hidden-md-and-down">
      <v-col>
        <h3>Your account info:</h3>
        <ul>
          <li>Username: {{ this.user.username }}</li>
          <li>Full Name: {{ this.user.full_name }}</li>
          <li>Address:{{ this.user.address }}</li>
          <li>Role: {{ this.user.role }}</li>
          <li>Created at: {{ this.user.created_at }}</li>
        </ul></v-col
      >
      <v-col>
        <h3>Performance Ratings:</h3>
        <ul>
          <li>
            Fixed Problems:
            <span class="success--text font-weight-bold">{{
              this.problem_summary.fixed
            }}</span>
          </li>
          <li>
            Unfixed:
            <span class="red--text font-weight-bold">{{
              this.problem_summary.unfixed
            }}</span>
          </li>
          <li>
            Pendings:
            <span class="orange--text font-weight-bold">{{
              this.problem_summary.pending
            }}</span>
          </li>

          <!-- TODO Finish this. make api call for fixed problems and make a graph of attendance for the last 12 months -->
        </ul>
        <h2>Success Rate: {{ this.success_rate_percentage }} %</h2>
      </v-col>
    </v-row>

    <div class="hidden-md-and-up">
      <h3>Your account info:</h3>
      <ul>
        <li>Username: {{ this.user.username }}</li>
        <li>Full Name: {{ this.user.full_name }}</li>
        <li>Address:{{ this.user.address }}</li>
        <li>Role: {{ this.user.role }}</li>
        <li>Created at: {{ this.user.created_at }}</li>
      </ul>
      <h3>Performance Ratings:</h3>
      <ul>
        <li>
          Fixed Problems:
          <span class="success--text font-weight-bold">{{
            this.problem_summary.fixed
          }}</span>
        </li>
        <li>
          Unfixed:
          <span class="red--text font-weight-bold">{{
            this.problem_summary.unfixed
          }}</span>
        </li>
        <li>
          Pendings:
          <span class="orange--text font-weight-bold">{{
            this.problem_summary.pending
          }}</span>
        </li>

        <!-- TODO Finish this. make api call for fixed problems and make a graph of attendance for the last 12 months -->
      </ul>
      <h2>Success Rate: {{ this.success_rate_percentage }} %</h2>
    </div>

    <v-divider class="mt-4 mb-4"> </v-divider>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      user: "auth/user",
      problem_summary: "problem/problem_summary",
    }),

    success_rate_percentage() {
      var fixed = this.problem_summary.fixed;
      var unfixed = this.problem_summary.unfixed;
      var total = fixed + unfixed;
      var unfixed_perc = (unfixed / total) * 100;
      var success_rate_percentage = 100 - unfixed_perc;

      //   return Math.toFixed(success_rate_percentage, 2);
      return success_rate_percentage.toFixed(1);
    },
  },
  methods: {
    ...mapActions({
      get_problem_summary: "problem/get_problem_summary",
    }),
  },
  created() {
    if (this.problem_summary.length <= 0) {
      console.log("no problem");
      this.get_problem_summary(this.user.id);
    }
  },
};
</script>

<style></style>
