<template>
  <v-container>
    <h2>Reseller's Table</h2>
    <v-text-field
      v-model="search"
      placeholder="Search anything here"
      prepend-icon="mdi-magnify"
    ></v-text-field>
    <v-data-table
      :items="items"
      :headers="headers"
      :search="search"
    ></v-data-table>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      search: "",
      headers: [
        {
          text: "id",
          value: "id",
        },
        {
          text: "Item Name",
          value: "item_name",
        },
        {
          text: "Item Type",
          value: "item_type",
        },
        { text: "Unit", value: "unit" },
        { text: "Available Stocks", value: "quantity" },
        { text: "SDP", value: "reseller_price" },
        { text: "SRP", value: "selling_price" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      items: "item/items",
    }),
  },
};
</script>

<style></style>
