var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',[_vm._v("Unpaid Invoices")]),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-title',[_vm._v("1 Month Due")]),_c('v-card-text',[_c('v-data-table',{attrs:{"items":_vm.unpaid_invoices_summary[0],"headers":_vm.unpaid_summary_headers}})],1)],1)],1),_c('v-col',[_c('v-card',[_c('v-card-title',[_vm._v("2 Months Due")]),_c('v-card-text',[_c('v-data-table',{attrs:{"items":_vm.unpaid_invoices_summary[1],"headers":_vm.unpaid_summary_headers}})],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-title',[_vm._v("3 Months Due")]),_c('v-card-text',[_c('v-data-table',{attrs:{"items":_vm.unpaid_invoices_summary[2],"headers":_vm.unpaid_summary_headers}})],1)],1)],1),_c('v-col',[_c('v-card',[_c('v-card-title',[_vm._v("More than 3 months Due")]),_c('v-card-text',[_c('v-data-table',{attrs:{"items":_vm.unpaid_invoices_summary[3],"headers":_vm.unpaid_summary_headers}})],1)],1)],1)],1),_c('div',{staticClass:"d-flex mb-2"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"success","text":"","loading":_vm.loading},on:{"click":_vm.refresh}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-refresh")]),_vm._v("Refresh")],1)],1),_c('v-text-field',{attrs:{"label":"Search Unpaid","prepend-icon":"mdi-magnify","placeholder":"Search"},model:{value:(_vm.unpaid_search),callback:function ($$v) {_vm.unpaid_search=$$v},expression:"unpaid_search"}}),_c('v-data-table',{attrs:{"loading":_vm.loading,"dense":"","items":_vm.unpaid_invoices,"headers":_vm.unpaid_headers,"search":_vm.unpaid_search},scopedSlots:_vm._u([{key:"item.balance",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"error--text font-weight-bold"},[_vm._v(_vm._s(item.balance))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('PayablesModal',{attrs:{"invoice":item}}),_c('AddPaymentModal',{attrs:{"invoice":item}}),_c('confirm-invoice-print-modal',{attrs:{"item":item}})],1)]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [(item.is_quote == 1)?_c('span',{staticClass:"green--text font-weight-bold"},[_vm._v(" Quote")]):_c('span',{staticClass:"red--text font-weight-bold"},[_vm._v("Invoice")])]}},{key:"item.invoice_status",fn:function(ref){
var item = ref.item;
return [(item.invoice_status == 'With Balance')?_c('span',{staticClass:"warning--text"},[_vm._v(_vm._s(item.invoice_status))]):_c('span',{staticClass:"--text"},[_vm._v("Waiting for payment")])]}},{key:"item.is_invoice",fn:function(ref){
var item = ref.item;
return [(item.is_invoice)?_c('v-chip',{staticClass:"white--text",attrs:{"color":"blue"}},[_vm._v("Invoice")]):_c('v-chip',{staticClass:"white--text",attrs:{"color":"red"}},[_vm._v("Bill")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }