var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticClass:"d-flex"},[_c('h2',[_vm._v("Daily Journal Summary")]),_c('v-spacer'),_c('v-btn',{attrs:{"small":"","color":"success"},on:{"click":_vm.refresh}},[_vm._v("Refresh")])],1),(!_vm.loading)?_c('v-data-table',{attrs:{"items":_vm.journal_entries,"headers":_vm.journal_headers,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.credit",fn:function(ref){
var item = ref.item;
return [(!item.is_debit)?_c('span',[_vm._v(_vm._s(item.amount))]):_vm._e()]}},{key:"item.debit",fn:function(ref){
var item = ref.item;
return [(item.is_debit)?_c('span',[_vm._v(_vm._s(item.amount))]):_vm._e()]}},{key:"item.account_title",fn:function(ref){
var item = ref.item;
return [(item.chart_of_account.account_title == 'Cash')?_c('v-chip',{attrs:{"color":"success"}},[_vm._v(_vm._s(item.chart_of_account.account_title))]):(
          item.chart_of_account.account_title == 'Accounts Receivables'
        )?_c('v-chip',{attrs:{"color":"orange"}},[_vm._v(_vm._s(item.chart_of_account.account_title))]):(item.chart_of_account.account_title == 'Service Revenue')?_c('v-chip',{attrs:{"color":"yellow"}},[_vm._v(_vm._s(item.chart_of_account.account_title))]):(item.chart_of_account.account_title == 'Sales/Item Sales')?_c('v-chip',{attrs:{"color":"primary"}},[_vm._v(_vm._s(item.chart_of_account.account_title))]):(item.chart_of_account.account_title == 'Gcash')?_c('v-chip',{attrs:{"color":"primary"}},[_vm._v(_vm._s(item.chart_of_account.account_title))]):(item.chart_of_account.account_title == 'Software Revenues')?_c('v-chip',{attrs:{"color":"error"}},[_vm._v(_vm._s(item.chart_of_account.account_title))]):_c('v-chip',{attrs:{"color":"grey"}},[_vm._v(" "+_vm._s(item.chart_of_account.account_title)+" ")])]}},{key:"item.invoice_id",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.invoice_id)}},[_vm._v(_vm._s(item.invoice_id))])]}},{key:"item.action",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","icon":"","color":"red","loading":_vm.loading},on:{"click":function($event){return _vm.del(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,false,2874257075)}):_vm._e(),_c('h2',[_vm._v(" Total Debit: "),_c('span',{staticClass:"success--text"},[_vm._v(_vm._s(_vm.total_debit_credit.debit))]),_vm._v(" | Total Credit: "),_c('span',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(_vm.total_debit_credit.credit))])]),_c('v-btn',{staticClass:"mt-4",attrs:{"color":"primary","loading":_vm.loading},on:{"click":_vm.post_journal}},[_vm._v("Post Journal")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }