<template>
  <!-- picked up units -->
  <div>
    <div class="d-flex mb-2">
      <h2>Picked up Units</h2>
    </div>
    <v-text-field
      v-model="picked_up_search"
      label="Search"
      prepend-icon="mdi-magnify"
      placeholder="Search for anything"
    ></v-text-field>
    <v-data-table
      :items="picked_up_units"
      :headers="picked_up_units_headers"
      :search="picked_up_search"
    >
      <template v-slot:item.unit_brand_and_model="{ item }">
        {{ item.unit_brand }} - {{ item.unit_model }}
      </template>

      <template v-slot:item.problems="{ item }">
        <ol>
          <li v-for="problem in item.problems" :key="problem.id">
            <p
              :class="
                problem.status == 'Fixed'
                  ? 'success--text font-weight-bold'
                  : 'orange--text'
              "
            >
              {{ problem.problem_description }} -
              {{ problem.status }}
            </p>
          </li>
        </ol>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  methods: {
    ...mapActions({
      get_picked_up_units: "summary/get_picked_up_units",
    }),
  },
  created() {
    if (this.picked_up_units.length <= 0) {
      this.get_picked_up_units();
    }
  },
  computed: {
    ...mapGetters({
      picked_up_units: "summary/picked_up_units",
    }),
  },
  data() {
    return {
      picked_up_search: "",
      picked_up_units_headers: [
        {
          text: "Unit ID",
          value: "id",
        },
        {
          text: "Customer Name",
          value: "customer.customer_name",
        },
        {
          text: "Contact Number",
          value: "customer.customer_contact_no",
        },
        {
          text: "Unit Type",
          value: "unit_type",
        },
        {
          text: "Unit Brand and Model",
          value: "unit_brand_and_model",
        },
        {
          text: "Problems",
          value: "problems",
        },
        {
          text: "Picked up by",
          value: "picked_up_by",
        },
        {
          text: "Picked up date",
          value: "picked_up_date",
        },
      ],
    };
  },
};
</script>

<style></style>
