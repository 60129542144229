<template>
  <v-card width="330">
    <v-card-title>
      <h3 class="pa-5">{{ date }}</h3></v-card-title
    >
    <v-card-text>
      <div class="pl-4 pr-4 pb-2 mt-n3">
        <div class="d-flex mb-3">
          <h3>
            Cash In:
            <span class="green--text">{{ number(data.paymentsTotal) }}</span>
          </h3>
          <h3 class="ml-3">
            Cash Out:
            <span class="red--text"> {{ number(data.expenseTotal) }}</span>
          </h3>
          <h3 class="ml-3">
            CoH:
            <span class="blue--text">{{
              number(data.paymentsTotal - data.expenseTotal)
            }}</span>
          </h3>
        </div>
        <summary-list-modal :data="data" :date="date" class="mt-3" />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";
import summaryListModal from "./summaryListModal.vue";
export default {
  components: { summaryListModal },
  created() {
    console.log(this.data);
  },
  methods: {
    number(data) {
      var formatter = new Intl.NumberFormat("en-us", {});
      return formatter.format(data);
    },
  },
  props: ["date", "data"],
  computed: {
    date_today() {
      var date = moment().format("MMM Do Y");
      return date;
    },
  },
};
</script>

<style scoped></style>
