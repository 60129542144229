<template>
  <div>
    <v-btn @click="dialog = true" color="warning" small> Edit Purpose </v-btn>
    <v-dialog v-model="dialog" persistent width="500">
      <v-card>
        <v-card-title>Edit Purpose</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="invoice.purpose"
            label="Purpose"
            prepend-icon="mdi-pen"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="submit" :loading="loading"
            >Submit</v-btn
          >
          <v-btn @click="dialog = false"> Close</v-btn>
          <v-spacer></v-spacer>
          <p class="caption grey--text">editInvoiceModal.vue</p>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: ["invoice"],
  data() {
    return {
      dialog: false,
      loading: false,
    };
  },
  methods: {
    submit() {
      this.loading = true;
      let x = window.confirm("Are you sure you want to proceed?");
      if (x) {
        axios
          .put("update_invoice", this.invoice)
          .then(() => {
            alert("Successfully edited purpose. Please refresh the table.");
          })
          .catch((err) => {
            alert(err);
          });
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
