<template>
  <!-- unpaid invoices -->
  <div>
    <h2>Unpaid Invoices</h2>
    <v-row>
      <v-col
        ><v-card>
          <v-card-title>1 Month Due</v-card-title>
          <v-card-text>
            <v-data-table
              :items="unpaid_invoices_summary[0]"
              :headers="unpaid_summary_headers"
            ></v-data-table>
          </v-card-text> </v-card
      ></v-col>
      <v-col>
        <v-card>
          <v-card-title>2 Months Due</v-card-title>
          <v-card-text>
            <v-data-table
              :items="unpaid_invoices_summary[1]"
              :headers="unpaid_summary_headers"
            ></v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>3 Months Due</v-card-title>
          <v-card-text>
            <v-data-table
              :items="unpaid_invoices_summary[2]"
              :headers="unpaid_summary_headers"
            ></v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-card-title>More than 3 months Due</v-card-title>
          <v-card-text>
            <v-data-table
              :items="unpaid_invoices_summary[3]"
              :headers="unpaid_summary_headers"
            ></v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <div class="d-flex mb-2">
      <v-spacer></v-spacer>
      <v-btn color="success" text @click="refresh" :loading="loading"
        ><v-icon class="mr-2">mdi-refresh</v-icon>Refresh</v-btn
      >
    </div>
    <v-text-field
      v-model="unpaid_search"
      label="Search Unpaid"
      prepend-icon="mdi-magnify"
      placeholder="Search"
    ></v-text-field>
    <v-data-table
      :loading="loading"
      dense
      :items="unpaid_invoices"
      :headers="unpaid_headers"
      :search="unpaid_search"
    >
      <template v-slot:item.balance="{ item }">
        <p class="error--text font-weight-bold">{{ item.balance }}</p>
      </template>
      <template v-slot:item.action="{ item }">
        <div class="d-flex">
          <PayablesModal :invoice="item" />
          <AddPaymentModal :invoice="item" />
          <confirm-invoice-print-modal :item="item" />
        </div>
      </template>
      <template v-slot:item.type="{ item }">
        <span v-if="item.is_quote == 1" class="green--text font-weight-bold">
          Quote</span
        >
        <span v-else class="red--text font-weight-bold">Invoice</span>
      </template>
      <!-- payment status -->
      <template v-slot:item.invoice_status="{ item }">
        <span
          v-if="item.invoice_status == 'With Balance'"
          class="warning--text"
          >{{ item.invoice_status }}</span
        >
        <span v-else class="--text">Waiting for payment</span>
      </template>
      <template v-slot:item.is_invoice="{ item }">
        <v-chip color="blue" class="white--text" v-if="item.is_invoice"
          >Invoice</v-chip
        >
        <v-chip color="red" class="white--text" v-else>Bill</v-chip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AddPaymentModal from "@/components/Payment/addPaymentModal.vue";
import PayablesModal from "@/components/Payment/payablesModal.vue";
import ConfirmInvoicePrintModal from "../Payment/confirmInvoicePrintModal.vue";
export default {
  components: {
    PayablesModal,
    AddPaymentModal,
    ConfirmInvoicePrintModal,
  },
  data() {
    return {
      unpaid_summary_headers: [
        { text: "id", value: "id" },
        { text: "Customer Name", value: "customer.customer_name" },
        { text: "Total Amount", value: "amount" },
        {
          text: "Balance",
          value: "balance",
        },
        { text: "Invoice Date", value: "invoice_date" },
      ],
      loading: false,
      unpaid_search: "",
      unpaid_headers: [
        {
          text: "",
          value: "action",
        },
        { text: "ID", value: "id" },
        { text: "Invoice Code", value: "invoice_code" },
        {
          text: "Customer Name",
          value: "customer.customer_name",
        },
        { text: "Status", value: "invoice_status" },
        {
          text: "Balance",
          value: "balance",
        },
        {
          text: "Total Amount",
          value: "total_amount",
        },
        { text: "Invoice Date", value: "invoice_date" },

        { text: "Type", value: "is_invoice" },
        { text: "Purpose", value: "purpose" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      unpaid_invoices: "invoice/unpaid_invoices",
      unpaid_invoices_summary: "summary/unpaid_invoices_summary",
    }),
  },
  created() {
    if (this.unpaid_invoices.length <= 0) this.get_unpaid_invoices();
    if (this.unpaid_invoices_summary.length <= 0)
      this.get_unpaid_invoices_summary();
  },
  methods: {
    refresh() {
      this.loading = true;
      this.get_unpaid_invoices().then(() => {
        this.loading = false;
      });
    },
    ...mapActions({
      get_unpaid_invoices: "invoice/get_unpaid_invoices",
      get_unpaid_invoices_summary: "summary/get_unpaid_invoices_summary",
    }),
  },
};
</script>
