<template>
  <div class="mt-10 mb-10">
    <div v-if="ready">
      <div class="d-flex">
        <h2>Summary of Collections</h2>

        <v-spacer></v-spacer>
        <print-sales-summary-modal />
        <v-btn
          color="success"
          class=""
          icon
          x-large
          @click="refresh"
          :loading="loading"
          ><v-icon>mdi-refresh</v-icon></v-btn
        >
      </div>

      <p class="overline">Figures of Collection for this month</p>
      <div class="d-flex">
        <div v-for="item in sales_summary" :key="item.id" class="ma-3">
          <total-card-2 :date="item.date" :data="item.data" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import totalCard2 from "../totalCard2.vue";
import { mapActions, mapGetters } from "vuex";
import PrintSalesSummaryModal from "../Item/printSalesSummaryModal.vue";
export default {
  components: { totalCard2, PrintSalesSummaryModal },

  data() {
    return {
      ready: true,
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      get_sales_summary: "summary/get_sales_summary",
    }),
    refresh() {
      this.loading = true;
      this.get_sales_summary().then(() => {
        this.loading = false;
      });
    },
  },
  computed: {
    ...mapGetters({
      sales_summary: "summary/sales_summary",
    }),
  },
  created() {
    if (this.sales_summary.length == 0) this.get_sales_summary();
  },
};
</script>

<style></style>
