<template>
  <div>
    <v-btn small @click="dialog = true">View List</v-btn>
    <v-dialog v-model="dialog" persistent width="1100">
      <v-card class="pa-4">
        <v-card-title> </v-card-title>
        <v-card-text>
          <div class="d-flex">
            <h1 class="mt-">Invoice Payments</h1>
            <v-spacer></v-spacer>
            <h3 class="green--text">{{ date }}</h3>
          </div>

          <v-data-table
            class="mt-4"
            :headers="cash_in_headers"
            :items="data.paymentsRow"
          ></v-data-table>

          <h3 class="green--text">
            Total Invoice Payments:{{ data.paymentsTotal }}
          </h3>
          <hr class="mt-4 mb-4" />
          <h1>Bills Payments</h1>
          <v-data-table
            class="mt-4"
            :headers="cash_in_headers"
            :items="data.expenseRow"
            dense
          ></v-data-table>
          <h3 class="red--text">
            Total Bills Payments: {{ data.expenseTotal }}
          </h3>
          <hr class="mt-4 mb-4" />
          <journal-daily-summary />
        </v-card-text>
        <v-card-actions>
          <v-btn @click="dialog = false"> Close</v-btn>
          <v-spacer></v-spacer>
          <p class="caption grey--text">summaryListModal.vue</p>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import journalDailySummary from "./Dashboard/journalDailySummary.vue";
export default {
  components: { journalDailySummary },
  props: ["data", "date"],
  created() {
    console.log(this.data);
  },
  data() {
    return {
      dialog: false,
      loading: false,
      cash_in_headers: [
        {
          text: "Payment ID",
          value: "id",
        },
        {
          text: "Invoice Code",
          value: "invoice.invoice_code",
        },
        {
          text: "Customer Name",
          value: "invoice.customer.customer_name",
        },
        {
          text: "Amount",
          value: "amount_paid",
        },
        {
          text: "Payment Method",
          value: "chart_of_account.account_title",
        },
        {
          text: "Date Inserted",
          value: "created_at",
        },
        {
          text: "Purpose",
          value: "invoice.purpose",
        },
      ],

      expense_list_headers: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Expense Description",
          value: "expense_description",
        },
        {
          text: "Amount",
          value: "expense_amount",
        },
        {
          text: "Expense Type",
          value: "expense_type",
        },
        {
          text: "Date Inserted",
          value: "date_paid",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
